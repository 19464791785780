import React from "react"
import { graphql } from "gatsby"
import { LocalizedLink } from "gatsby-theme-i18n"

const TaxonomyTerm = ({ name, path }) => (
  <div className="listing-blog__filtres__name">
    {path?.alias && <LocalizedLink to={path.alias}>{name}</LocalizedLink>}
  </div>
)

export default TaxonomyTerm

export const query = graphql`
  fragment TaxonomyTermTag on taxonomy_term__tag {
    id
    name
    path {
      alias
    }
  }
  fragment TaxonomyTermCategories on taxonomy_term__categories {
    id
    name
    path {
      alias
    }
  }
`
