import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { navigate, graphql } from "gatsby"
import { FormattedMessage } from "react-intl"

import Layout from "components/layout"
import SEO from "components/seo"
import Blog from "components/system/blog"
import Pager from "components/system/pager"
import TaxonomyTerm from "components/system/taxonomy-term"
import { getQueryParams, getArticles, countArticles } from "utils/search"

import loup from "../images/loup.webp"
import vague from "../images/vague.webp"

import "scss/blog.scss"

const Search = ({
  data: { allTaxonomyTermCategories, allTaxonomyTermTag },
}) => {
  const { search } = useLocation()
  const { q, page } = getQueryParams({ search })
  const currentPage = parseInt(page) || 1

  const [searchText, setSearchText] = useState(q)
  const [numPages, setNumPages] = useState(1)
  const [blog, setBlog] = useState([])

  useEffect(() => {
    getArticles({ search: q, page: currentPage }).then((res) => setBlog(res))
    countArticles({ search: q }).then((res) => setNumPages(res))
  }, [q, currentPage])

  const handleSubmit = (e) => {
    e.preventDefault()

    navigate(`/search?q=${searchText}`, {
      state: {
        disableScrollUpdate: true,
      },
    })

    getArticles({ search: searchText, page: currentPage }).then((res) =>
      setBlog(res)
    )
    countArticles({ search: searchText }).then((res) => setNumPages(res))
  }

  const metadata = {
    url: "/search",
    title: "Blog",
  }

  return (
    <Layout>
      <SEO {...metadata} />
      <div className="header-blue">
        <div className="container">
          <div className="blog__header">
            <h1>
              <span>Blog</span>
            </h1>
            <span
              className="overline"
              style={{
                backgroundImage: `url(${vague})`,
              }}
            ></span>
          </div>
          <div className="listing-blog">
            <div className="listing-blog__filtres">
              <form action="/search" method="get" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="q"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Découvrez des actualités, des articles ..."
                />
                <button
                  type="submit"
                  className="icon-loup"
                  aria-label="icon-loup"
                  style={{
                    backgroundImage: `url(${loup})`,
                  }}
                />
              </form>
              <div className="listing-blog__filtres--title">Categorie</div>
              {allTaxonomyTermCategories.edges.map(
                ({ node: { id, name, path } }) => (
                  <TaxonomyTerm key={id} name={name} path={path} />
                )
              )}
            </div>
            <div className="listing-blog__articles">
              {blog && blog.length ? (
                blog.map((node) => {
                  node = node?.node || node
                  return <Blog key={node.id} {...node} />
                })
              ) : (
                <>
                  <p>
                    <FormattedMessage id="No results for this search" />
                  </p>
                  <ul>
                    <li>Vérifiez que l'orthographe est correcte.</li>
                  </ul>
                </>
              )}
            </div>
          </div>
          {blog && blog.length ? (
            <Pager
              path={`/search?${(searchText && `q=${searchText}`) || ``}`}
              url={`&page=`}
              currentPage={currentPage}
              numPages={numPages}
              ellipses={{
                previous: currentPage > 3,
                next: currentPage < numPages - 2,
              }}
            />
          ) : null}
        </div>
      </div>
    </Layout>
  )
}

export default Search

export const query = graphql`
  query SearchQuery($locale: String = "fr") {
    allTaxonomyTermCategories(filter: { langcode: { eq: $locale } }) {
      edges {
        node {
          ...TaxonomyTermCategories
        }
      }
    }
    allTaxonomyTermTag(filter: { langcode: { eq: $locale } }) {
      edges {
        node {
          ...TaxonomyTermTag
        }
      }
    }
  }
`
