import React from "react"
import { LocalizedLink } from "gatsby-theme-i18n"

const Pager = ({
  path = `/blog`,
  url = `/page/`,
  currentPage,
  numPages,
  ellipses: { previous, next },
}) => (
  <div className="listing-blog__pager">
    {currentPage !== 1 && (
      <div className="prev-page">
        <LocalizedLink
          to={`${path}${
            currentPage - 1 !== 1 ? `${url}${parseInt(currentPage) - 1}` : ``
          }`}
          rel="prev"
        />
      </div>
    )}
    {currentPage - 1 !== 1 && (
      <div className="page">
        <LocalizedLink
          className={currentPage === 1 ? `current` : ``}
          to={`${path}${url === `/page/` ? `/` : ``}`}
        >
          1
        </LocalizedLink>
      </div>
    )}
    {previous && (
      <div className="page">
        <span>&hellip;</span>
      </div>
    )}
    {Array.from({ length: numPages }, (_, i) => {
      if (
        i === currentPage - 2 ||
        (currentPage !== 1 && i === currentPage - 1) ||
        (currentPage !== numPages - 1 && i === currentPage)
      )
        return (
          <div className="page" key={i}>
            <LocalizedLink
              className={currentPage === i + 1 ? `current` : ``}
              key={`pagination-number${i + 1}`}
              to={`${path}${
                i !== 0
                  ? `${url === "/page/" ? `${url}${i + 1}/` : `${url}${i + 1}`}`
                  : ``
              }`}
            >
              {i + 1}
            </LocalizedLink>
          </div>
        )
      else return null
    })}
    {next && (
      <div className="page">
        <span>&hellip;</span>
      </div>
    )}
    {currentPage !== numPages && (
      <div className="page">
        <LocalizedLink
          className={currentPage === numPages ? `current` : ``}
          to={`${path}${url}${numPages}`}
        >
          {numPages}
        </LocalizedLink>
      </div>
    )}
    {currentPage !== numPages && (
      <div className="next-page">
        <LocalizedLink
          to={`${path}${url}${parseInt(currentPage) + 1}`}
          rel="next"
        />
      </div>
    )}
  </div>
)

export default Pager
