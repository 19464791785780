import axios from "axios"
import Jsona from "jsona"
import Qs from "qs"

export const getQueryParams = ({ search }) => {
  if (!search) return { q: "", page: 1 }

  search = search.substr(1)

  return Qs.parse(search)
}

const params = (q) => {
  const filter = q
    ? {
        filter: {
          title: {
            operator: "CONTAINS",
            value: q,
          },
        },
      }
    : {}

  return {
    include:
      "field_categorie,field_tag,field_blog_image,field_blog_image.field_media_image",
    ...filter,
  }
}

export async function getArticles({ search, page }) {
  const dataFormatter = new Jsona()

  const { data } = await axios({
    baseURL: `${process.env.GATSBY_API_URL}/jsonapi`,
    url: `/node/blog`,
    params: params(search),
    paramsSerializer: function (params) {
      return Qs.stringify(
        {
          ...params,
          page: {
            offset: parseInt(page) - 1,
            limit: 4,
          },
        },
        { arrayFormat: "brackets" }
      )
    },
  })

  return dataFormatter.deserialize(data)
}

export async function countArticles({ search }) {
  const {
    data: { data },
  } = await axios({
    baseURL: `${process.env.GATSBY_API_URL}/jsonapi`,
    url: `/node/blog`,
    params: params(search),
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: "brackets" })
    },
  })

  return data.length || 1
}
