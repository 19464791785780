import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n"
import { Media, Share } from "components/helpers"
import { localizedPath } from "utils/helpers"

const Blog = ({
  id,
  title,
  field_chapo,
  field_tag,
  field_blog_image,
  path,
  relationships,
}) => {
  if (relationships?.field_tag) field_tag = relationships.field_tag
  const { locale, defaultLang } = useLocalization()
  const baseUrl = process.env.GATSBY_API_URL

  return (
    <div className="shadow-box">
      {relationships?.field_blog_image && (
        <Media
          className="article__thumb"
          field_image_media={relationships.field_blog_image}
        />
      )}
      {field_blog_image && (
        <img
          className="article__thumb"
          src={`${baseUrl}/${field_blog_image?.field_media_image?.uri?.url}`}
          alt=""
        />
      )}
      {JSON.stringify(field_blog_image?.field_media_image?.alt)}
      <div className="articles articles__text">
        <LocalizedLink className="articles__title_link" to={path.alias}>
          <h2 className="articles__title">{title}</h2>
        </LocalizedLink>
        <p className="articles__details">{field_chapo}</p>
        <div className="articles__rs">
          <LocalizedLink className="cta cta__blue" to={path.alias}>
            <FormattedMessage id="readMore" />
          </LocalizedLink>
          <div className="articles__share">
            <Share
              url={localizedPath(defaultLang, locale, `${path.alias}`)}
              title={`${title} | Blog Fullwave`}
              subject={`${title} | Blog Fullwave`}
              via={`fullwaveagency`}
            />
          </div>
        </div>
        {field_tag?.length > 0 && (
          <div className="post-tags">
            {field_tag?.map(({ id, name, path }) => (
              <div key={id}>
                <LocalizedLink to={path.alias}>{name}</LocalizedLink>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Blog

export const query = graphql`fragment Blog on node__blog {
  id
  title
  path {
    alias
  }
  field_chapo
  relationships {
    field_tag {
      id
      name
      path {
        alias
      }
    }
    field_blog_image {
      field_media_image {
        alt
      }
      relationships {
        field_media_image {
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
